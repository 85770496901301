import { StateCreator } from "zustand";
import { CourseDetail, Raiting } from "../../types/course";

export interface courseDetailState {
  detail: CourseDetail;
  rating: Raiting;
  reload: boolean;
  screen: string;
  setDetail: (data: CourseDetail) => void;
  setRating: (data: Raiting) => void;
  setReload: (data: boolean) => void;
  setScreen: (data: string) => void;

}

export const courseDetailSlice: StateCreator<courseDetailState> = (set) => ({
  detail: {
    id: 0,
    name: "",
    code: "",
    description: "",
    image: "",
    template: "",
    title: "",
    courseUrl: "",
    createdDate: "",
    publicDate: "",
    industries: [],
    cost: 0,
    assigners: [],
    sections: [],
    totalComment: 0,
    totalStudents: 0,
    learningStatus: 0,
    isAbleToComment: false
  },
  rating: {
    rate: 0,
    count: 0
  },
  reload: true,
  screen: "",
  setDetail: (detail) => {
    set(() => ({
      detail: detail,
    }))
  },
  setRating: (rating) => {
    set(() => ({
      rating: rating,
    }))
  },
  setReload: (value: boolean) => {
    set({ reload: value });
  },
  setScreen: (value: string) => {
    set({ screen: value });
  },
});
