import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./global.scss";
import { ConfigProvider } from "antd";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <ConfigProvider
    theme={{
      token: { fontFamily: "Inter", colorPrimary: "#7F56D9" },
      components: {
        Progress: {
          defaultColor: "#7F56D9",
        },
      },
    }}
  >
    <App />{" "}
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
